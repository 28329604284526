import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45')
];

export const server_loads = [0];

export const dictionary = {
		"/": [5],
		"/about": [~6],
		"/admin": [~7],
		"/admin/metrics": [~8],
		"/admin/orders": [~9],
		"/auth/driver/order/[[jobref]]": [10],
		"/auth/driver/sign-in": [~11],
		"/auth/forgot-password": [~12],
		"/auth/reset-password/[token]": [~13,[],[2]],
		"/auth/sign-in": [~14],
		"/auth/sign-out": [~15],
		"/auth/sign-up": [~16],
		"/buyer": [~17],
		"/buyer/orders": [~18],
		"/buyer/orders/crud[[qid]]": [~19,[],[3]],
		"/buyer/settings": [~20],
		"/buyer/users": [~21],
		"/driver": [~22],
		"/driver/job-list": [~23],
		"/driver/order/[[jobref]]": [~24],
		"/driver/profile": [~25],
		"/driver/truck-list": [~26],
		"/home": [~27],
		"/lab-x": [~29],
		"/lab5": [~30],
		"/lab": [28],
		"/order/delivery[jobref]": [~31],
		"/order/pickup[[jobref]]": [~32],
		"/profile": [~33],
		"/sentry-example": [34],
		"/testrank": [~35],
		"/transporter": [~36],
		"/transporter/assets": [~37],
		"/transporter/calendar": [~38],
		"/transporter/drivers": [~39],
		"/transporter/orders": [~40],
		"/transporter/orders/confirmed[[quoteid]]": [~41,[],[4]],
		"/transporter/rates": [~42],
		"/transporter/settings": [~43],
		"/transporter/trailers": [~44],
		"/transporter/users": [~45]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';